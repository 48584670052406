import dynamic from 'next/dynamic';
import { IMarketPlaceMenu, IPageMenu } from '../../interfaces';
import { useGlobalContext } from '../../lib/context';
import { NoSSR } from '../ui';
import LayoutTracking from './LayoutTracking';
import { useEffect, useState } from 'react';
import { ILoginData } from '@/interfaces/ILogin';
import { LoginType } from '@/lib/constants';
import { getCookie } from '@/lib/helpers/Cookie';
import router, { useRouter } from 'next/router';

const DynamicHeader = dynamic(() => import('../shared/header/Header'), {
  suspense: true
})
const DynamicFooter = dynamic(() => import('../shared/footer/Footer'), {
  suspense: true,
})
const MobileMenu = dynamic(() => import('../shared/header/MobileMenu').then((mod) => mod.MobileMenu), {
  suspense: true,
})
const DynamicMarketPlaceHeader = dynamic(() => import('../marketplace/header/MarketPlaceHeader').then((mod) => mod.MarketPlaceHeader), {
  suspense: true,
})
const DynamicHeaderChilds = dynamic(() => import('../shared/header/HeaderChilds').then((mod) => mod.HeaderChilds), {
  suspense: true
})
export function Layout(props: { children: any, HeaderData: IPageMenu[], FooterData: IPageMenu[], utmSource: string ,marketPlaceHeaderData:IMarketPlaceMenu[] }) {
  const { props: { pageURL, isMobileView } } = useGlobalContext();
  const isheaderFooterNeeded = pageURL.includes("interior/inco-estimate") || pageURL.includes("homeco/inco-estimate") || pageURL.includes("/payment/") || pageURL.includes('project-planner') || pageURL.includes('builder-package') || pageURL.includes('modular-kitchen-cost-calculator') || pageURL.includes('/blocks/boq') || pageURL.includes('/rate-your-experience') || pageURL.includes('/checkout/');
  const isMarketPlaceHeaderNeeded = pageURL.includes('/online-store')
  const [isUserLogin, setIsUserLogin] = useState<ILoginData | null>(null);
  const router = useRouter();
  useEffect(() => {
    const LoginData = getCookie(LoginType.LOGIN);
    LoginData ? setIsUserLogin(JSON.parse(LoginData)) : setIsUserLogin(null);
  }, [router.query]);
  return (
    <>
      {!isheaderFooterNeeded && <DynamicHeader HeaderData={props.HeaderData} isUserLogin={isUserLogin} />}
      {isMarketPlaceHeaderNeeded && <DynamicMarketPlaceHeader marketPlaceHeaderData={props.marketPlaceHeaderData} isUserLogin={isUserLogin}/>}
      <main>{props.children}</main>
      {!isheaderFooterNeeded && <DynamicHeaderChilds HeaderData={props.HeaderData} />}
      {(!isheaderFooterNeeded && isMobileView) && <MobileMenu HeaderData={props.HeaderData} isUserLogin={isUserLogin} />}
      {!isheaderFooterNeeded && <DynamicFooter FooterData={props.FooterData} />}
      <NoSSR>
        <LayoutTracking utmSource={props.utmSource} />
      </NoSSR>
    </>
  );
}